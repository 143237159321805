import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import Theme from '../../Theme';
import * as Ant from 'antd';

function AboutCommittee(props) {
  const [records, setRecords] = React.useState([]);
  const [selectedTitle, setSelectedTitle] = React.useState(null);
  const {id} = qs.parse(props.location.search);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const _records = await JStorage.fetchAllDocuments(
          'supervisor',
          {},
          ['-date'],
          null,
          {
            token: null,
          },
        );
        setRecords(_records);
        setSelectedTitle(_records[0].title);
      } catch (ex) {
        console.warn('REV_DBG', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id]);

  const selectedRecord = records.find((it) => it.title === selectedTitle);

  return (
    <Wrapper>
      {selectedRecord && (
        <>
          <h1>理監事</h1>
          <Ant.Select
            value={selectedTitle}
            onChange={setSelectedTitle}
            style={{width: 200, marginBottom: 20}}>
            {records.map((it) => {
              return (
                <Ant.Select.Option value={it.title} key={it.title}>
                  {it.title}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedRecord.raw_html?.enabled
                ? selectedRecord.raw_html.html
                : selectedRecord.html,
            }}
          />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

export default withPageEntry(AboutCommittee);
